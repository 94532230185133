export const getFullProductName = product => {
    return `${product.name || ''} ${product.code ? `[${product.code}]` : ''}`
}

export const filterProductsByStatuses = (
    products,
    { showActiveProducts, showInactiveProducts }
) => {
    if (!products) {
        return []
    }

    const allowedProductStatuses = []

    if (showActiveProducts) {
        allowedProductStatuses.push('Website Active')
    }

    if (showInactiveProducts) {
        allowedProductStatuses.push('Deactivated', 'Website Inactive')
    }

    return products.filter(p => {
        return allowedProductStatuses.includes(p.status)
    })
}
