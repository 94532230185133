import * as yup from 'yup'

import yupMessages from '../../../../../../helpers/yup/yup-messages'
import { ProductCodeSchema } from '../../../../../../helpers/yup/yup-schemas'

yupMessages()

const createSchema = (context = {}) => {
    const { products = [] } = context
    
    return yup
        .object({
            product: yup
                .mixed()
                .test(
                    'is-string-or-object',
                    'Must be string or object',
                    (value) => {
                        return value === '' || typeof value === 'object'
                    }
                ),
            upc: yup.string().when('product', {
                is: (val) => !val,
                then: () => ProductCodeSchema.test(
                    'upc-exists',
                    'Product with this UPC code not found',
                    (value) => {
                        if (!value || !products.length) return true
                        return products.some(p => p.upc === value)
                    }
                ),
                otherwise: () => yup.string().optional(),
            }),
        })
        .test(
            'at-least-one-field',
            'At least one field must be filled',
            (value) => {
                return value.product !== '' || value.upc !== ''
            }
        )
}

export default createSchema
