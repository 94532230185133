import React from 'react'

import Modal from '../../../../../../components/modal/Modal'

const ResultModal = ({ modalVisible, error, onModalClose }) => {
    return (
        <Modal visible={modalVisible} onClose={onModalClose}>
            <div className="modal-content">
                {error ? (
                    <p>{error}</p>
                ) : (
                    <>
                        <h3>Thank you for contacting Ken&rsquo;s!</h3>
                        <p className="text-center">
                            A Ken&rsquo;s representative will contact you soon
                            about your product request.
                        </p>
                    </>
                )}
            </div>
        </Modal>
    )
}

export default ResultModal
