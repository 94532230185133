import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { connect } from 'react-redux'

import FieldWrapper from '../../../../../../components/forms/form-parts/FieldWrapper'
import ProductCodeField from '../../../../../../components/forms/form-fields/ProductCodeField'
import { filterProductsByStatuses } from '../../../../utils/helpers'
import Form from '../../form-parts/Form'
import ProductsField from '../../form-fields/ProductsField'
import defaultValues from './defaultValues'
import createSchema from './schema'

const propTypes = {
    onSubmit: PropTypes.func.isRequired,
}

const FindProductForm = ({
    onSubmit,
    showActiveProducts = true,
    showInactiveProducts = false,
    // store
    products,
    productsLoading,
    productsError,
}) => {
    const filteredProducts = useMemo(() => {
        return filterProductsByStatuses(products, {
            showActiveProducts,
            showInactiveProducts,
        })
    }, [products, showActiveProducts, showInactiveProducts])

    // Form state
    const [schemaContext, setSchemaContext] = useState({ products: [] })

    // Update schema context when products are loaded
    useEffect(() => {
        if (filteredProducts && filteredProducts.length > 0) {
            setSchemaContext({ products: filteredProducts })
        }
    }, [filteredProducts])

    // Initialize form with dynamic resolver
    const methods = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: (values, context, options) => {
            // Use the current schemaContext to create a schema
            const schema = createSchema(schemaContext)

            return yupResolver(schema)(values, context, options)
        },
        context: schemaContext,
    })

    const { handleSubmit, watch, setValue } = methods

    // Watch for field changes to implement the "only one field at a time" logic
    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (value[name] !== '') {
                // only one field should be filled at a time
                Object.keys(value).forEach(key => {
                    if (key !== name && value[key] !== '') {
                        setValue(key, '', { shouldValidate: true })
                    }
                })
            }

            // Find selected product based on field values
            let selectedProduct
            if (value[name] && typeof value[name] === 'object') {
                selectedProduct = value[name]
            } else if (
                name === 'upc' &&
                value[name] &&
                value[name].length >= 12
            ) {
                selectedProduct = filteredProducts.find(
                    p => p.upc === value[name]
                )
            }

            // Call onSubmit callback with selected product
            onSubmit && onSubmit(selectedProduct)
        })

        return () => subscription.unsubscribe()
    }, [watch, setValue, onSubmit, filteredProducts])

    // Form submission handler
    const submitForm = formValues => {
        // do nothing
        return
    }

    return (
        <Form methods={methods} onSubmit={handleSubmit(submitForm)}>
            <FieldWrapper name="product">
                <ProductsField
                    name="product"
                    showActiveProducts={showActiveProducts}
                    showInactiveProducts={showInactiveProducts}
                />
            </FieldWrapper>
            <FieldWrapper name="upc">
                <ProductCodeField
                    name="upc"
                    placeholder={
                        productsLoading
                            ? 'Loading...'
                            : productsError
                            ? 'Error. Reaload the page'
                            : 'UPC Code'
                    }
                    disabled={productsLoading}
                />
            </FieldWrapper>
        </Form>
    )
}

FindProductForm.propTypes = propTypes

const mapStateToProps = state => ({
    products: state.faq.products.list,
    productsLoading: state.faq.products.loading,
    productsError: state.faq.products.error,
})

export default connect(mapStateToProps)(FindProductForm)
