import React, { useCallback, useEffect, useRef, useState } from 'react'
import _merge from 'lodash/merge'
import { withRouter } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import FieldWrapper from '../../../../../../components/forms/form-parts/FieldWrapper'
import InputField from '../../../../../../components/forms/form-fields/InputField'
import SelectField from '../../../../../../components/forms/form-fields/SelectField'
import StateField from '../../../../../../components/forms/form-fields/StateField'
import TextAreaField from '../../../../../../components/forms/form-fields/TextAreaField'
import ReCaptcha from '../../../../../../components/re-captcha/ReCaptcha'
import ProductsField from '../../form-fields/ProductsField'
import Form from '../../form-parts/Form'
import FormActions from '../../form-parts/FormActions'
import { numberOfLocationsOptions, operatorSegmentOptions } from './config'
import defaultValuesOriginal from './defaultValues'
import schema from './schema'

const InquiryForm = ({
    onSubmit,
    products,
    loading,
    shouldClearForm,
    defaultValues,
    showActiveProducts,
    showInactiveProducts,
    // router
    location,
}) => {
    const methods = useForm({
        mode: 'onBlur',
        defaultValues: _merge({}, defaultValuesOriginal, defaultValues),
        resolver: yupResolver(schema),
    })

    const { reset, handleSubmit, setValue } = methods

    // set product from url
    useEffect(() => {
        if (!products || !products.length || !location.search) {
            return
        }

        const params = new URLSearchParams(location.search)

        const productFromUrl = params.get('product')

        if (productFromUrl) {
            const targetProduct = products.find(
                ({ _id }) => _id === productFromUrl
            )

            if (targetProduct) {
                setValue('products', [targetProduct])
            }
        }
    }, [products, location.search, setValue])

    const recaptchaRef = useRef()

    const [captcha, setCaptcha] = useState(null)

    const [captchaValidation, setCaptchaValidation] = useState(null)

    const submitHandler = formValues => {
        if (!captcha) {
            setCaptchaValidation('Please complete the reCAPTCHA')

            return
        }

        const products = formValues.products.map(product => {
            return {
                id: product._id,
            }
        })

        onSubmit({
            formValues: { ...formValues, products },
            captcha,
        })
    }

    const handleCaptchaChange = useCallback(
        value => {
            setCaptcha(value)

            setCaptchaValidation(null)
        },
        [setCaptcha, setCaptchaValidation]
    )

    const resetForm = e => {
        e && e.preventDefault()

        reset()
    }

    useEffect(() => {
        if (shouldClearForm) {
            reset()

            setCaptcha(null)

            recaptchaRef.current.reset()

            setCaptchaValidation(null)
        }
    }, [shouldClearForm, reset, recaptchaRef])

    return (
        <>
            <p>
                <em>(Fields with * are required)</em>
            </p>
            <Form methods={methods} onSubmit={handleSubmit(submitHandler)}>
                <FieldWrapper name="state">
                    <StateField
                        name="state"
                        placeholder="Select state*"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="city">
                    <InputField
                        name="city"
                        placeholder="Select city*"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="products">
                    <ProductsField
                        isMulti
                        name="products"
                        placeholder="Select product / Enter product code*"
                        disabled={loading}
                        showActiveProducts={showActiveProducts}
                        showInactiveProducts={showInactiveProducts}
                    />
                </FieldWrapper>
                <FieldWrapper name="company">
                    <InputField
                        name="company"
                        placeholder="Company name*"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="companyWebsite">
                    <InputField
                        name="companyWebsite"
                        placeholder="Company website"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="numberOfLocations">
                    <SelectField
                        name="numberOfLocations"
                        placeholder="Number of locations"
                        options={numberOfLocationsOptions}
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="segment">
                    <SelectField
                        name="segment"
                        placeholder="Operator segment"
                        options={operatorSegmentOptions}
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="distributorName">
                    <InputField
                        name="distributorName"
                        placeholder="Distributor(s)"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="fullName">
                    <InputField
                        name="fullName"
                        placeholder="Your name*"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="email">
                    <InputField
                        name="email"
                        type="email"
                        placeholder="Email address*"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="phone">
                    <InputField
                        name="phone"
                        type="tel"
                        placeholder="Phone number"
                        disabled={loading}
                    />
                </FieldWrapper>
                <FieldWrapper name="message">
                    <TextAreaField
                        name="message"
                        placeholder="Please provide more information to help us direct your inquiry*"
                        disabled={loading}
                    />
                </FieldWrapper>
                <ReCaptcha
                    recaptchaRef={recaptchaRef}
                    onChange={handleCaptchaChange}
                />
                {captchaValidation && (
                    <p style={{ color: '#900' }}>{captchaValidation}</p>
                )}
                <FormActions onClear={resetForm} submitLabel="Submit" />
            </Form>
        </>
    )
}

export default withRouter(InquiryForm)
