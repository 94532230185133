import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { submitPurchaseForm, clearPurchaseForm } from '../../../store/actions'
import ServiceWrapper from '../../service-parts/ServiceWrapper'
import ServiceSubhead from '../../service-parts/ServiceSubhead'
import InquiryForm from '../../forms/shared-forms/inquiry-form'
import ResultModal from '../../forms/shared-forms/inquiry-form/ResultModal'

const PurchaseService = ({
    // store
    products,
    loading,
    success,
    error,
    // actions
    submitPurchaseForm,
    clearPurchaseForm,
}) => {
    const [modalVisible, setModalVisible] = useState()

    const onSubmit = formValues => {
        submitPurchaseForm({ ...formValues, inquirySource: 'locatorForm' })
    }

    const onModalClose = useCallback(
        e => {
            e && e.preventDefault()

            setModalVisible(false)

            if (success) {
                clearPurchaseForm()
            }
        },
        [clearPurchaseForm, success]
    )

    useEffect(() => {
        if (success || error) {
            setModalVisible(true)
        }
    }, [success, error])

    return (
        <ServiceWrapper>
            <ServiceSubhead>
                <>
                    Contact us and you will be contacted by a Ken&rsquo;s
                    representative in your area. If you need immediate
                    assistance call 508-948-4724.
                </>
            </ServiceSubhead>
            <InquiryForm
                onSubmit={onSubmit}
                products={products}
                loading={loading}
                shouldClearForm={success}
                // show active products only
                showActiveProducts={true}
                showInactiveProducts={false}
            />
            <ResultModal
                modalVisible={modalVisible}
                error={error}
                onModalClose={onModalClose}
            />
        </ServiceWrapper>
    )
}

const mapStateToProps = state => ({
    products: state.faq.products.list,
    loading: state.faq.purchaseForm.loading,
    success: state.faq.purchaseForm.success,
    error: state.faq.purchaseForm.error,
})

export default connect(mapStateToProps, {
    submitPurchaseForm,
    clearPurchaseForm,
})(PurchaseService)
