const defaultValues = {
    state: '',
    city: '',
    products: [],
    company: '',
    companyWebsite: '',
    numberOfLocations: '',
    segment: '',
    distributorName: '',
    fullName: '',
    email: '',
    phone: '',
    message: '',
}

export default defaultValues
