import React, { useMemo } from 'react'
import { connect } from 'react-redux'

import FieldInfo from '../../../../../components/forms/form-parts/raw/FieldInfo'
import ProductsFieldOriginal from '../../../../../components/forms/form-fields/ProductsField'
import { filterProductsByStatuses } from '../../../utils/helpers'

const ProductsField = ({
    products,
    placeholder,
    productsLoading,
    productsError,
    showActiveProducts = true,
    showInactiveProducts = false,
    ...props
}) => {
    const filteredProducts = useMemo(() => {
        return filterProductsByStatuses(products, {
            showActiveProducts,
            showInactiveProducts,
        })
    }, [products, showActiveProducts, showInactiveProducts])

    return (
        <FieldInfo info="Select from the drop down menu, enter one or more products, or the three/four digit code from the lower left side of the product label.">
            <ProductsFieldOriginal
                {...props}
                placeholder={
                    productsError
                        ? 'Error. Reload the page.'
                        : placeholder || 'Select product / Enter product code'
                }
                optionsLoading={productsLoading}
                options={filteredProducts || []}
            />
        </FieldInfo>
    )
}

const mapStateToProps = state => ({
    products: state.faq.products.list,
    productsLoading: state.faq.products.loading,
    productsError: state.faq.products.error,
})

export default connect(mapStateToProps)(ProductsField)
