import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import { connect } from 'react-redux'

import {
    setActiveProdServiceScreen,
    setActiveProdSetAvInqFormState,
} from '../../../../store/actions'
import { getFullProductName } from '../../../../utils/helpers'
import ServiceSubhead from '../../../service-parts/ServiceSubhead'
import ServiceResult from '../../../service-parts/service-result'
import ServiceInfo from '../../../service-parts/service-info'
import ProductLinks from '../../../service-parts/product-links'
import FindProductForm from '../../../forms/shared-forms/find-product-form'
import { AVAILABILITY_FORM_SCREEN, FIND_PRODUCT_SCREEN } from '../constants'

const FindProductScreen = ({
    // store
    visibleScreen,
    // actions
    setActiveProdServiceScreen,
    setActiveProdSetAvInqFormState,
}) => {
    const [result, setResult] = useState(null)
    const [activeProduct, setActiveProduct] = useState(null)
    const [isActive, setIsActive] = useState(null)

    const onSubmit = useCallback(product => {
        if (!product) {
            setResult()

            setActiveProduct()

            setIsActive()

            return
        }

        const productName = getFullProductName(product)
        const productID = product.id || ''
        const status = product.status || ''
        const isAvailable = status.toLowerCase() === 'website active'

        setResult({
            title: `${productName}`,
            value: isAvailable ? 'Available' : null,
            url: `/products/${productID}`,
        })

        isAvailable && setIsActive(true)

        setActiveProduct(product)
    }, [])

    return (
        <div
            className={classNames('find-product-screen', {
                'hidden-element': visibleScreen !== FIND_PRODUCT_SCREEN,
            })}
        >
            <ServiceSubhead>
                <>
                    Enter information into <strong>one</strong> of the following
                    fields to check product availability. If you do not find
                    what you are looking for in the drop-down, please contact
                    use at <span className="no-wrap">508-9480-4724</span>{' '}
                    between 8:30 AM - 5:00 PM EST M-F.
                </>
            </ServiceSubhead>
            <FindProductForm
                onSubmit={onSubmit}
                // show active and inactive products
                showActiveProducts={true}
                showInactiveProducts={true}
            />
            <ServiceResult
                result={
                    result ? (
                        <>
                            {result.title ? <span>{result.title}:</span> : null}
                            {result.value ? (
                                <h3>{result.value}</h3>
                            ) : (
                                <>
                                    <div className="form-group">
                                        for product availability, please call us
                                        at (508)-948-4724 or
                                    </div>
                                    <button
                                        className="small-btn inverse"
                                        onClick={() => {
                                            setActiveProdSetAvInqFormState({
                                                product: activeProduct,
                                            })

                                            setActiveProdServiceScreen(
                                                AVAILABILITY_FORM_SCREEN
                                            )
                                        }}
                                    >
                                        contact us online
                                    </button>
                                </>
                            )}
                        </>
                    ) : null
                }
                product={activeProduct}
                imageClassName={classNames({
                    unavailable: !isActive,
                })}
            />
            {isActive && (
                <ProductLinks
                    result={result ? result : null}
                    product={activeProduct}
                />
            )}
            <ServiceInfo result={result ? result : null} />
        </div>
    )
}

const mapStateToProps = state => ({
    visibleScreen: state.faq.productActiveService.visibleScreen,
})

export default connect(mapStateToProps, {
    setActiveProdServiceScreen,
    setActiveProdSetAvInqFormState,
})(FindProductScreen)
