import { lazy } from 'react'

const AllergenService = lazy(() => import('./components/services/allergen'))
const ExpirationDateService = lazy(() =>
    import('./components/services/expiration-date')
)
const GlutenFreeService = lazy(() =>
    import('./components/services/gluten-free')
)
const KosherService = lazy(() => import('./components/services/kosher'))
const MGFReadService = lazy(() => import('./components/services/mfg-read'))
const MFGShowService = lazy(() => import('./components/services/mfg-show'))
const PurchaseService = lazy(() => import('./components/services/purchase'))
const ProductActiveService = lazy(() =>
    import('./components/services/product-active')
)
const ShelfLifeLocationService = lazy(() =>
    import('./components/services/shelf-life-location')
)

export const services = [
    {
        slug: 'allergen',
        navTitle: 'Allergen Checker',
        title: 'Have an allergen question?',
        tooltip: "What is the expiration date of an unopened Ken's gallon?",
        component: AllergenService,
    },
    {
        slug: 'gluten-free',
        navTitle: 'Gluten-Free Checker',
        title:
            "How to determine if a Ken's Foodservice product is Gluten-Free?",
        tooltip: "Is the Ken's Foodservice product Gluten-Free?",
        component: GlutenFreeService,
    },
    {
        slug: 'kosher',
        navTitle: 'Kosher Checker',
        title:
            "How can you tell if a Ken's Foodservice product is Kosher or Kosher Dairy?",
        tooltip: "Is the Ken's Foodservice product Kosher or Kosher Dairy?",
        component: KosherService,
    },
    {
        slug: 'manufacture-code-show',
        navTitle: 'MFG (Manufacturing) Code Finder',
        title:
            "How is a MFG (Manufacture Code) shown on the Ken's product or case of product?",
        tooltip:
            "How do you read a MFG (Manufacture Code) shown on the Ken's product?",
        component: MFGShowService,
    },
    {
        slug: 'manufacture-code-read',
        navTitle: 'How to Read MFG (Manufacturing) Code',
        title:
            "How do you read a MFG (Manufacture Code) shown on the Ken's product?",
        tooltip:
            "How do you read a MFG (Manufacture Code) shown on the Ken's product?",
        component: MGFReadService,
    },
    {
        slug: 'product-active',
        navTitle: 'Product Availability Checker',
        title:
            "Does Ken's Foodservice still produce the product I am looking for?",
        tooltip:
            "Does Ken's Foodservice still produce the product I am looking for?",
        component: ProductActiveService,
    },
    {
        slug: 'where-purchase',
        navTitle: 'Product Locator Inquiries',
        title: "Where can I purchase my Ken's Foodservice product?",
        tooltip: "Where can I purchase my Ken's Foodservice product?",
        component: PurchaseService,
    },
    {
        slug: 'product-shelf-life-location',
        navTitle: 'Shelf Life Checker',
        title:
            "Where on the Ken's Foodservice website is the product shelf life located?",
        tooltip:
            "Where on the Ken's Foodservice website is the product shelf-life located?",
        component: ShelfLifeLocationService,
    },
    {
        slug: 'expiration-date',
        navTitle: 'Shelf Life Expiration Date Calculator',
        title: "What is the expiration date of an unopened Ken's gallon?",
        tooltip:
            "What is the expiration date of an unopened Ken's gallon or other sized product?",
        component: ExpirationDateService,
    },
]
