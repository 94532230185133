import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { clearActiveProdState } from '../../../store/actions'
import ServiceWrapper from '../../service-parts/ServiceWrapper'
import FindProductScreen from './components/FindProductScreen'
import AvailabilityFormScreen from './components/AvailabilityFormScreen'
import { AVAILABILITY_FORM_SCREEN } from './constants'

const ProductActiveService = ({
    // store
    visibleScreen,
    // actions
    clearActiveProdState,
}) => {
    useEffect(() => {
        return () => {
            // clear state on unmount
            clearActiveProdState()
        }
    }, [clearActiveProdState])

    return (
        <ServiceWrapper>
            {/* visible by default */}
            <FindProductScreen />
            {visibleScreen === AVAILABILITY_FORM_SCREEN ? (
                <AvailabilityFormScreen />
            ) : null}
        </ServiceWrapper>
    )
}

const mapStateToProps = state => ({
    visibleScreen: state.faq.productActiveService.visibleScreen,
})

export default connect(mapStateToProps, { clearActiveProdState })(
    ProductActiveService
)
