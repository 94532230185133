import { setLocale } from 'yup'

const yupMessages = () => {
    setLocale({
        mixed: {
            default: 'This field is invalid',
            required: 'This field is required',
            oneOf: 'This value is not valid for this field',
            notOneOf: 'This value is not valid for this field',
        },
        number: {
            typeError: 'This field must be a number',
            integer: 'This field must be a number',
            max: ({ max }) => `This value can't be greater then ${max}`,
            min: ({ min }) => `This value can't be less than ${min}`,
            moreThan: ({ more }) => `This value must be greater then ${more}`,
            lessThan: ({ less }) => `This value must be less than ${less}`,
        },
        string: {
            length: ({ length }) =>
                `This field must be exactly ${length} characters`,
            max: ({ max }) =>
                `The maximum length of this field ${max} characters`,
            min: ({ min }) =>
                `The minimum length of this field ${min} characters`,
            email: 'Must be a valid email',
            url: 'Must be a valid URL',
        },
        array: {
            min: ({ min }) =>
                `This field must have at least ${min} item${
                    min > 1 ? 's' : ''
                }`,
            max: ({ max }) =>
                `This field must have less than or equal to ${max} item${
                    max > 1 ? 's' : ''
                }`,
        },
    })
}

export default yupMessages
