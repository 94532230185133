import addDays from 'date-fns/addDays'

const isLeapYear = (year) => {
    // A year is a leap year if:
    // 1. It is divisible by 4, but not divisible by 100, OR
    // 2. It is divisible by 400.
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
}

// convert two digit year to full year
// e.g. 21 -> 2021
const convertToFullYear = (twoDigitYear) => {
    // Get the current year
    const currentYear = new Date().getFullYear()

    // Calculate the current century (e.g., 2000 for 2025, 1900 for 1930)
    const currentCentury = Math.floor(currentYear / 100) * 100

    // Convert the two-digit input to a full year
    const fullYear = currentCentury + twoDigitYear

    // Adjust if the full year is in the future (e.g., 2025 and input is 00 => 2000)
    if (fullYear > currentYear) {
        return fullYear - 100
    }

    return Number(fullYear)
}

export const calculateExpirationDate = ({
    shelfLife = 0,
    julianDate,
    year,
}) => {
    if (!Number(year) || String(year).length !== 2) {
        throw new Error('Invalid two digit year')
    }

    const fullYear = convertToFullYear(year)

    const startDate = new Date(fullYear, 0, 1)

    if (julianDate < 1 || julianDate > 366) {
        throw new Error('Invalid Julian date')
    }

    if (julianDate === 366 && !isLeapYear(fullYear)) {
        throw new Error('The selected year is not a leap year')
    }

    const expirationDate = addDays(startDate, julianDate + shelfLife - 1)

    return expirationDate
}

export const currentDate = () => {
    let newDate = new Date()
    let day = newDate.getDate()
    let month = newDate.getMonth() + 1
    let year = newDate.getFullYear()

    return `${month<10?`0${month}`:`${month}`}/${day<10?`0${day}`:`${day}`}/${year}`
}
