import * as yup from 'yup'

import yupMessages from '../../../../../../helpers/yup/yup-messages'

yupMessages()

const schema = yup.object({
    state: yup.string().max(2).required(),
    city: yup.string().max(500).required(),
    products: yup.array().min(1).required(),
    company: yup.string().max(500).required(),
    companyWebsite: yup.string().test('url', 'Please enter a valid URL', (value) => {
        if (!value) {
            return true;
        }

        // Basic domain pattern: at least one dot and valid characters
        const domainPattern = /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;
        
        if (value.startsWith('http://') || value.startsWith('https://')) {
            try {
                const url = new URL(value);
                return domainPattern.test(url.hostname);
            } catch {
                return false;
            }
        } else {
            return domainPattern.test(value);
        }
    }).max(1000),
    numberOfLocations: yup.string().max(1000),
    segment: yup.string().max(1000),
    distributorName: yup.string().max(1000),
    fullName: yup.string().min(3).max(500).required(),
    email: yup.string().email().max(200).required(),
    phone: yup.string().max(200).required(),
    message: yup.string().min(10).max(5000).required(),
})

export default schema
