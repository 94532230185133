import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import InputError from '../form-parts/InputError'
import TextAreaFieldRaw from './raw/TextAreaField'

const TextAreaField = ({ name, noError, children, ...textareaProps }) => {
    const methods = useFormContext()

    return (
        <div className="field-wrapper">
            <Controller
                name={name}
                control={methods.control}
                render={({ field }) => {
                    return <TextAreaFieldRaw {...textareaProps} {...field} />
                }}
            />
            {children}
            {!noError ? <InputError name={name} className="auto" /> : null}
        </div>
    )
}

export default TextAreaField
