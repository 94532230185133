import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import _get from 'lodash/get'

import {
    submitPurchaseForm,
    clearPurchaseForm,
    setActiveProdServiceScreen,
} from '../../../../store/actions'
import { getFullProductName } from '../../../../utils/helpers'
import ServiceSubhead from '../../../service-parts/ServiceSubhead'
import InquiryForm from '../../../forms/shared-forms/inquiry-form'
import ResultModal from '../../../forms/shared-forms/inquiry-form/ResultModal'
import { FIND_PRODUCT_SCREEN } from '../constants'

const AvailabilityFormScreen = ({
    // store
    products,
    loading,
    success,
    error,
    formState,
    // actions
    submitPurchaseForm,
    clearPurchaseForm,
    setActiveProdServiceScreen,
}) => {
    const [modalVisible, setModalVisible] = useState()

    // prepare default values for inquiry form
    const defaultValues = useMemo(() => {
        const result = {}

        const product = _get(formState, 'product')

        if (product) {
            result.products = [product]
            result.message = `I am interested in the the availability of ${getFullProductName(
                product
            )}`
        }

        return result
    }, [formState])

    const onSubmit = formValues => {
        submitPurchaseForm({ ...formValues, inquirySource: 'availabilityForm' })
    }

    const onModalClose = useCallback(
        e => {
            e && e.preventDefault()

            setModalVisible(false)

            if (success) {
                clearPurchaseForm()

                setActiveProdServiceScreen(FIND_PRODUCT_SCREEN)
            }
        },
        [clearPurchaseForm, setActiveProdServiceScreen, success]
    )

    useEffect(() => {
        if (success || error) {
            setModalVisible(true)
        }
    }, [success, error])

    return (
        <div className="availability-form-screen">
            <div className="form-group">
                <button
                    className="small-btn"
                    onClick={() =>
                        setActiveProdServiceScreen(FIND_PRODUCT_SCREEN)
                    }
                >
                    Back to Product Search
                </button>
            </div>
            <ServiceSubhead>
                <>
                    Contact us and you will be contacted by a Ken&rsquo;s
                    representative in your area. If you need immediate
                    assistance call 508-948-4724.
                </>
            </ServiceSubhead>
            <InquiryForm
                onSubmit={onSubmit}
                products={products}
                loading={loading}
                shouldClearForm={success}
                defaultValues={defaultValues}
                // show inactive products only
                showActiveProducts={false}
                showInactiveProducts={true}
            />
            <ResultModal
                modalVisible={modalVisible}
                error={error}
                onModalClose={onModalClose}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    products: state.faq.products.list,
    loading: state.faq.purchaseForm.loading,
    success: state.faq.purchaseForm.success,
    error: state.faq.purchaseForm.error,
    visibleScreen: state.faq.productActiveService.visibleScreen,
    formState: state.faq.productActiveService.formState,
})

export default connect(mapStateToProps, {
    submitPurchaseForm,
    clearPurchaseForm,
    setActiveProdServiceScreen,
})(AvailabilityFormScreen)
