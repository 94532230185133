import React, { useCallback, useState } from 'react'
import _get from 'lodash/get'

import { getFullProductName } from '../../../utils/helpers'
import ServiceWrapper from '../../service-parts/ServiceWrapper'
import ServiceSubhead from '../../service-parts/ServiceSubhead'
import ServiceResult from '../../service-parts/service-result'
import ServiceInfo from '../../service-parts/service-info'
import ProductLinks from '../../service-parts/product-links'
import FindProductForm from '../../forms/shared-forms/find-product-form'

import kosherIcon from '../../../../../assets/img/kosher-icon.png'

const KosherService = () => {
    const [result, setResult] = useState(null)

    const [activeProduct, setActiveProduct] = useState(null)

    const onSubmit = useCallback(product => {
        if (!product) {
            setResult()

            setActiveProduct()

            return
        }

        const productName = getFullProductName(product)
        const productID = product.id || ''

        let status = 'Not Kosher'

        // Yes/No
        const kosherStatus = _get(product, 'marketing.kosher', '').toLowerCase()

        if (kosherStatus === 'yes') {
            status = (
                <span>
                    Kosher <img src={kosherIcon} alt="kosher dairy" />
                </span>
            )

            // Yes/No
            const kosherDairyStatus = _get(
                product,
                'marketing.kosherDairy',
                ''
            ).toLowerCase()

            if (kosherDairyStatus === 'yes') {
                status = (
                    <span>
                        Kosher Dairy <img src={kosherIcon} alt="kosher dairy" />{' '}
                        D
                    </span>
                )
            }
        }

        setResult({
            title: `${productName}`,
            value: status,
            url: `/products/${productID}`,
        })

        setActiveProduct(product)
    }, [])

    return (
        <ServiceWrapper>
            <ServiceSubhead>
                <>
                    Enter information into <strong>one</strong> of the following
                    fields for the Kosher or Kosher Dairy Status of our
                    products:
                </>
            </ServiceSubhead>
            <FindProductForm onSubmit={onSubmit} />
            <ServiceResult
                result={
                    result ? (
                        <>
                            <span>{result.title} is:</span>
                            <h3>{result.value}</h3>
                        </>
                    ) : null
                }
                product={activeProduct}
            />
            <ProductLinks
                result={result ? result : null}
                product={activeProduct}
            />
            <ServiceInfo result={result ? result : null} />
        </ServiceWrapper>
    )
}

export default KosherService
