import { actionTypes as types } from './constants'

export const fetchAllProducts = () => ({
    type: types.FAQ_FETCH_ALL_PRODUCTS_START,
})

// purchase service
export const submitPurchaseForm = (payload = {}) => ({
    type: types.FAQ_SUBMIT_PURCHASE_FORM_START,
    payload,
})

export const clearPurchaseForm = () => ({
    type: types.FAQ_CLEAR_PURCHASE_FORM,
})

// product active service
export const setActiveProdServiceScreen = screen => ({
    type: types.FAQ_ACTIVE_PROD_SERVICE_SET_SCREEN,
    payload: screen,
})

export const setActiveProdSetAvInqFormState = (payload = {}) => ({
    type: types.FAQ_ACTIVE_PROD_SET_AV_INQ_FORM_STATE,
    payload,
})

export const setActiveProdClearAvInqFormState = () => ({
    type: types.FAQ_ACTIVE_PROD_CLEAR_AV_INQ_FORM_STATE,
})

export const clearActiveProdState = () => ({
    type: types.FAQ_ACTIVE_PROD_CLEAR_STATE,
})
