import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import ServiceWrapper from '../../service-parts/ServiceWrapper'
import ServiceSubhead from '../../service-parts/ServiceSubhead'
import ServiceResult from '../../service-parts/service-result'
import ServiceInfo from '../../service-parts/service-info'
import ProductLinks from '../../service-parts/product-links'
import FindProductForm from '../../forms/shared-forms/find-product-form'
import { getFullProductName } from '../../../utils/helpers'

const ShelfLifeLocationService = () => {
    const [result, setResult] = useState(null)

    const [activeProduct, setActiveProduct] = useState(null)

    const onSubmit = useCallback(product => {
        if (!product) {
            setResult()

            setActiveProduct()

            return
        }

        const productName = getFullProductName(product)
        const productID = product.id || ''

        const shelfLife = product.shelfLife || 0

        setResult({
            title: `${productName}`,
            value: `${shelfLife} ${shelfLife === 1 ? 'day' : 'days'}`,
            url: `/products/${productID}`,
        })

        setActiveProduct(product)
    }, [])

    return (
        <ServiceWrapper>
            <ServiceSubhead>
                <p>
                    You can view the shelf life for any product here or visit
                    our{' '}
                    <Link to="/products" className="no-wrap" target="_blank">
                        product pages
                    </Link>
                    .<br /> Shelf life is listed in the "Storage, transport and
                    usage" section.
                </p>
                <p>
                    Enter information below in any field for shelf life
                    information on any of our products:
                </p>
            </ServiceSubhead>
            <FindProductForm onSubmit={onSubmit} />
            <ServiceResult
                result={
                    result ? (
                        <>
                            <span>
                                The shelf life for
                                <br /> {result.title}:
                            </span>
                            <h3>{result.value}</h3>
                        </>
                    ) : null
                }
                product={activeProduct}
            />
            <ProductLinks
                result={result ? result : null}
                product={activeProduct}
            />
            <ServiceInfo result={result ? result : null} />
        </ServiceWrapper>
    )
}

export default ShelfLifeLocationService
