import React, { useCallback, useState } from 'react'

import ServiceWrapper from '../../service-parts/ServiceWrapper'
import ServiceSubhead from '../../service-parts/ServiceSubhead'
import ServiceResult from '../../service-parts/service-result'
import ServiceInfo from '../../service-parts/service-info'
import ProductLinks from '../../service-parts/product-links'
import FindProductForm from '../../forms/shared-forms/find-product-form'
import { getFullProductName } from '../../../utils/helpers'

const AllergenService = () => {
    const [result, setResult] = useState(null)

    const [activeProduct, setActiveProduct] = useState(null)

    const onSubmit = useCallback(product => {
        if (!product) {
            setResult()

            setActiveProduct()

            return
        }

        const productName = getFullProductName(product)
        const productID = product.id || ''

        /*
            {
                "crustacean": "Not Intentionally Added",
                "eggs": "Not Intentionally Added",
                "fish": "Not Intentionally Added",
                "milk": "Not Intentionally Added",
                "soy": "Not Intentionally Added",
                "treenuts": "Not Intentionally Added",
                "wheat": "Contains",
                "peanuts": "Free From"
            }
        */
        const allergens = Object.entries(product.allergens || {})
            .filter(([_, value]) => {
                return value === 'Contains'
            })
            .map(([key]) => {
                return key.charAt(0).toUpperCase() + key.slice(1)
            })
            .join(', ')

        setResult({
            title: `${productName}`,
            value: allergens || 'None',
            url: `/products/${productID}`,
        })

        setActiveProduct(product)
    }, [])

    return (
        <ServiceWrapper>
            <ServiceSubhead>
                Enter information into <strong>one</strong> of the following
                fields for a list of product allergens:
            </ServiceSubhead>
            <FindProductForm onSubmit={onSubmit} />
            <ServiceResult
                result={
                    result ? (
                        <>
                            <span>
                                The allergens for
                                <br /> {result.title}:
                            </span>
                            <h3>{result.value}</h3>
                        </>
                    ) : null
                }
                product={activeProduct}
            />
            <ProductLinks
                result={result ? result : null}
                product={activeProduct}
            />
            <ServiceInfo result={result ? result : null} />
        </ServiceWrapper>
    )
}

export default AllergenService
