export const actionTypes = {
    FAQ_SUBMIT_PURCHASE_FORM_START: 'FAQ_SUBMIT_PURCHASE_FORM_START',
    FAQ_SUBMIT_PURCHASE_FORM_SUCCESS: 'FAQ_SUBMIT_PURCHASE_FORM_SUCCESS',
    FAQ_SUBMIT_PURCHASE_FORM_FAILED: 'FAQ_SUBMIT_PURCHASE_FORM_FAILED',
    FAQ_CLEAR_PURCHASE_FORM: 'FAQ_CLEAR_PURCHASE_FORM',

    FAQ_FETCH_ALL_PRODUCTS_START: 'FAQ_FETCH_ALL_PRODUCTS_START',
    FAQ_FETCH_ALL_PRODUCTS_SUCCESS: 'FAQ_FETCH_ALL_PRODUCTS_SUCCESS',
    FAQ_FETCH_ALL_PRODUCTS_FAILED: 'FAQ_FETCH_ALL_PRODUCTS_FAILED',

    FAQ_ACTIVE_PROD_SERVICE_SET_SCREEN: 'FAQ_ACTIVE_PROD_SERVICE_SET_SCREEN',
    FAQ_ACTIVE_PROD_SET_AV_INQ_FORM_STATE:
        'FAQ_ACTIVE_PROD_SET_AV_INQ_FORM_STATE',
    FAQ_ACTIVE_PROD_CLEAR_AV_INQ_FORM_STATE:
        'FAQ_ACTIVE_PROD_CLEAR_AV_INQ_FORM_STATE',
    FAQ_ACTIVE_PROD_CLEAR_STATE:
        'FAQ_ACTIVE_PROD_CLEAR_STATE',
}
