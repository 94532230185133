export const numberOfLocationsOptions = [
    { value: 'one', label: '1' },
    { value: 'twoTen', label: '2-10' },
    { value: '11more', label: '11+' },
]

export const operatorSegmentOptions = [
    { value: 'restaurant', label: 'Restaurant' },
    { value: 'k12', label: 'K-12 School' },
    { value: 'college', label: 'College/University' },
    { value: 'healthcare', label: 'Healthcare' },
    { value: 'business', label: 'Business & Industry' },
    { value: 'military', label: 'Military' },
    { value: 'recreation', label: 'Recreation' },
    { value: 'convenience', label: 'Convenience Store' },
    { value: 'supermarket', label: 'Supermarket / Deli' },
    { value: 'vending', label: 'Vending' },
    { value: 'other', label: 'Other' },
]
