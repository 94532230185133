import _cloneDeep from 'lodash/cloneDeep'

import { actionTypes as types } from './constants'
import { FIND_PRODUCT_SCREEN } from '../components/services/product-active/constants'

const initialState = {
    products: {
        list: null,
        loading: false,
        error: '',
    },
    purchaseForm: {
        success: false,
        loading: false,
        error: '',
    },
    productActiveService: {
        visibleScreen: FIND_PRODUCT_SCREEN,
        formState: {},
    },
}

export default (state = initialState, action) => {
    let updatedState = _cloneDeep(state)

    switch (action.type) {
        // load products
        case types.FAQ_FETCH_ALL_PRODUCTS_START:
            updatedState.products.loading = true
            updatedState.products.error = ''
            break
        case types.FAQ_FETCH_ALL_PRODUCTS_SUCCESS:
            updatedState.products.list = action.payload.data || []
            updatedState.products.loading = false
            break
        case types.FAQ_FETCH_ALL_PRODUCTS_FAILED:
            updatedState.products.loading = false
            updatedState.products.error = action.error
            break

        // submit purchase form
        case types.FAQ_SUBMIT_PURCHASE_FORM_START:
            updatedState.purchaseForm = {
                ...updatedState.purchaseForm,
                success: false,
                error: '',
                loading: true,
            }
            break
        case types.FAQ_SUBMIT_PURCHASE_FORM_SUCCESS:
            updatedState.purchaseForm = {
                ...updatedState.purchaseForm,
                success: true,
                loading: false,
            }
            break
        case types.FAQ_SUBMIT_PURCHASE_FORM_FAILED:
            updatedState.purchaseForm = {
                ...updatedState.purchaseForm,
                loading: false,
                error: action.error,
            }
            break
        case types.FAQ_CLEAR_PURCHASE_FORM:
            updatedState.purchaseForm = {
                ...initialState.purchaseForm,
            }
            break

        // product active service
        case types.FAQ_ACTIVE_PROD_SERVICE_SET_SCREEN:
            updatedState.productActiveService.visibleScreen = action.payload
            break
        case types.FAQ_ACTIVE_PROD_SET_AV_INQ_FORM_STATE:
            updatedState.productActiveService.formState = action.payload
            break
        case types.FAQ_ACTIVE_PROD_CLEAR_AV_INQ_FORM_STATE:
            updatedState.productActiveService.formState = {}
            break
        case types.FAQ_ACTIVE_PROD_CLEAR_STATE:
            updatedState.productActiveService = _cloneDeep(
                initialState.productActiveService
            )
            break

        default:
            break
    }

    return updatedState
}
